import Api from '@/services/Index';

/* Student Affairs */
const studentAffairs = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/minor/application/preferences/student-affairs', config);
}
const studentAffairsApprove = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/minor/application/preferences/student-affairs/approve', formData);
}

/* Prep School*/
const prepSchool = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/minor/application/preferences/prep-school', config);
}
const prepSchoolApprove = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/minor/application/preferences/prep-school/approve', formData);
}

/* Prep School*/
const academicUnitOffice = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/minor/application/preferences/academic-unit-office', config);
}
const academicUnitOfficeApprove = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/minor/application/preferences/academic-unit-office/approve', formData);
}

const store = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/minor/application/preferences', formData);
}

const save = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/minor/application/preferences/save');
}

const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/minor/application/preferences/' + id);
}

export default {
    studentAffairs,
    studentAffairsApprove,

    prepSchool,
    prepSchoolApprove,

    academicUnitOffice,
    academicUnitOfficeApprove,

    store,
    save,
    del,
}
